import React from "react"
import { Seo } from "../../components/seo"
import JobFormEn from "../../components/forms/JobFormEn"
import { Link } from "gatsby"

const JobFormPageEn = () => {
  return (
    <>
      <Seo title={`Mexico job form`} language={"en"} />
      <section className="container english-page-body">
        <div className="row row-en">
          <div className="en-pages">
            <h1>Free Consultation Form</h1>

            <div className="consultation-form">
              <p className="text">
                Our company strives to meet the preferences of our registrants
                as much as possible. We can negotiate employment conditions with
                hiring companies on your behalf if needed. Please note that some
                requests may not be accommodated. For details on our support,
                please refer to the “<span><Link to="/en/process">Job Introduction Process</Link></span>”.
              </p>
            </div>
            <JobFormEn />
          </div>
        </div>
      </section>
    </>
  )
}

export default JobFormPageEn
